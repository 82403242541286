/**
 * Nuxt middleware for preventing users from accessing
 * admin routes.
 * @example
 * ```ts
 * setup() {
 *   definePageMeta({ layout: 'default', middleware: ['admin'] });
 * }
 * ```
 * @package fun-academy-campus
 */
export default defineNuxtRouteMiddleware(async (to) => {
  const currentUser = useCurrentUser();
  const { $logger } = useNuxtApp();
  if (!currentUser) {
    return await navigateTo(
      useLocalePath()({
        name: 'sign-out',
        query: { skip: 'loading', redirect: to.fullPath },
      })
    );
  }
  const { getUserClaims, getUserRoles } = useFirebase();
  const roles = await getUserRoles();
  const adminRole = roles.find((role) => 'Admin' === role.name);
  const editorRole = roles.find((role) => 'Editor' === role.name);
  const userRoles = (await getUserClaims())?.roles;
  const isAllowed: boolean =
    Boolean(userRoles) &&
    Boolean(
      userRoles?.includes(String(adminRole?.id)) ??
        userRoles?.includes(String(editorRole?.id))
    );
  if (isAllowed) {
    $logger.info('Access granted ✅');
  } else {
    $logger.warn('Access denied ❌');
    return await navigateTo(useLocalePath()({ name: 'access-denied' }));
  }
});
